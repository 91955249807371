export const reasonFormat = {
  'birthday': {label: 'День рождения', title: 'Благотворительный сбор в пользу DonorSearch по случаю моего дня рождения'},
  'special_case': {label: 'Особый случай', title: 'Благотворительный сбор в пользу DonorSearch по особому случаю'},
  'just_like_that': {label: 'Просто так', title: 'Благотворительный сбор в пользу DonorSearch'},
}

export const formatSum = (sum: string): string => {
  const numberWithoutDecimal = sum.split('.')[0].replace(/\s/g, '');
  const formattedNumber = numberWithoutDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return formattedNumber;
}

export const calcPersent = (current: string, total: string): number => {
  const formattedCurrent = Number(current.split('.')[0]);
  const formattedTotal = Number(total.split('.')[0]);

  const persent = formattedCurrent / formattedTotal * 100;

  if(persent >= 100) {
    return 100;
  } else {
    return persent;
  }
}

export const formatDate = (dateString: string): string => {
  const dateParts = dateString.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  return `${day}.${month}.${year}`;
}

export const timeWithoutSeconds = (time: string): string => {
  console.log()
  return time.slice(0, 5)
}

export const copyURL = () => {
  navigator.clipboard.writeText(`${window.location.href.split('?')[0]}?utm_source=share`);
};