import { formatDate, timeWithoutSeconds } from '../utils';
import styles from './fundraisingDonateCard.module.scss';

const FundraisingDonateCard = ({ payment }) => {

  return (
    <>
      <div className={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.card_top__userInfo}>
            <img src={payment?.userPhoto || '/static/images/icons/defaultUserAvatar.svg'} alt="" />
            {
              !!payment?.userFirstName?.length ?
              <p>{payment?.userFirstName} {payment?.userLastName?.slice(0,1)}{!!payment?.userLastName?.length ? '.' : ''}</p>
              : <p>Анонимный пользователь</p>
            }
          </div>

          <div className={styles.card_top__date}>
            <p>{formatDate(payment?.date?.split('T')[0])} {timeWithoutSeconds(payment?.date?.split('T')[1])}</p>
          </div>
        </div>

        <div className={styles.card_bottom}>
          <div className={styles.card_bottom__comment}>
            <p>{payment?.comment}</p>
          </div>

          <div className={styles.card_bottom__sum}>
            {
              !!payment?.sum?.length ?
              <p><b>{payment?.sum} ₽</b></p>
              : <p><b>сумма скрыта</b></p>
            }
          </div>
        </div>
      </div>

      <div className={styles.cardMobile}>
        <div className={styles.card_top}>
          <div className={styles.card_top__userInfo}>
            <img src={payment?.userPhoto || '/static/images/icons/defaultUserAvatar.svg'} alt="" />
            {
              !!payment?.userFirstName?.length ?
              <p>{payment?.userFirstName} {payment?.userLastName?.slice(0,1)}{!!payment?.userLastName?.length ? '.' : ''}</p>
              : <p>Анонимный пользователь</p>
            }
          </div>

          <div className={styles.card_top__date}>
            <div>
              <p>{formatDate(payment?.date?.split('T')[0])} {timeWithoutSeconds(payment?.date?.split('T')[1])}</p>
            </div>
            <div>
              {
                !!payment?.sum?.length ?
                <p><b>{payment?.sum} ₽</b></p>
                : <p><b>сумма скрыта</b></p>
              }
            </div>
          </div>
        </div>

        <div className={styles.card_bottom}>
          <div className={styles.card_bottom__comment}>
            <p>{payment?.comment}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundraisingDonateCard;